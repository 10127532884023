.paginator__container {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center; }
  .paginator__container .record-per-page-container__dropdown-field {
    min-width: 170px;
    margin-bottom: 0px;
    margin-top: 20px; }
    .paginator__container .record-per-page-container__dropdown-field div[class$="-menu"] {
      z-index: 9; }

.paginator {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 300px;
  width: 100%;
  margin: 20px 0;
  color: #1a282c; }
  .paginator.hide {
    display: none; }

.paginator__page {
  height: 15px;
  width: 15px;
  color: #1a282c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px; }
  .paginator__page.active {
    color: #1E5558;
    font-weight: bold; }

.paginator__prev {
  transform: rotate(90deg); }

.paginator__next {
  transform: rotate(-90deg); }
