.H1DesktopGreen {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1E5558; }

.H1DesktopBlack {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #1a282c; }

.H1DesktopWhite {
  font-family: 'Inter-Light';
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #f6f6f6; }

.H2Desktop {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal; }

.H2DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #1E5558; }

.DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #1E5558; }

.H2DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #ffffff; }
  @media (max-width: 768.98px) {
    .H2DesktopWhite {
      font-size: 30px;
      line-height: 1.33; } }

.H3Desktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }
  @media (max-width: 768.98px) {
    .H3Desktop {
      font-size: 24px;
      line-height: 2; } }

.H3BlackDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H3WhiteDesktop {
  font-family: 'Inter-Medium';
  font-size: 29px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.H4DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.H5DesktopGrey, .scholarship-card-sponsor-form-top .scholarship-card-sponsor-form-top__leading__label {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H5DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopBlack, .scholarship-card-sponsor-form-tags__single-tag {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H4DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff; }

.H4DesktopBlack {
  font-family: 'Inter-Medium';
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6Desktop {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.H6DesktopGrey {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.H6DesktopBlack, .scholarship-card-sponsor-form-tags__single-tag {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.H6DesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.PDesktopGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: black; }

.H6DesktopWhite {
  font-family: 'Inter-Medium';
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyLargeWhite {
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefault {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBold {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodyDefaultBoldGrey {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultBoldWhite {
  font-family: 'Inter-Bold';
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodyDefaultBoldBlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodyDefaultRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodyDefaultRegularBlack {
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMediumGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #979797; }

.BodySmallMediumWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallMediumBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.BodySmallMedium {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTA {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.CTABlue {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1C21DF; }

.CTAGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTAGreen {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.CTABlack {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.CTARed {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #FF542D; }

.CTAWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.CTAYellow {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffcd00; }

.BodySmallRegular {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal; }

.BodySmallRegularWhite {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.BodySmallRegularGrey {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.BodySmallRegularGreen {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558; }

.BodySmallRegularBlack {
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c; }

.LabelGrey {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373; }

.LabelWhite {
  font-family: 'Inter-Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff; }

.EyebrowBlack {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a282c;
  text-transform: uppercase; }

.EyebrowWhite {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase; }

.EyebrowGreen {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1E5558;
  text-transform: uppercase; }

.EyebrowGrey {
  font-family: 'Inter-Medium';
  font-size: 10px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #737373;
  text-transform: uppercase; }

.CTA-Anchor-Tag-css {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 50px;
  color: #ffffff;
  background-color: #1C21DF;
  border: 1px solid #1C21DF;
  letter-spacing: normal;
  font-family: 'Inter-Medium', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  transition: transform 0.25s;
  border-radius: 5px;
  cursor: pointer; }
  .CTA-Anchor-Tag-css__Text {
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center; }
    .CTA-Anchor-Tag-css__Text svg {
      margin-right: 12px;
      transform: rotate(180deg); }

.sponsor-card {
  background: #EFEFE8 !important; }

.scholarship-card {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 10px 10px 15px;
  max-width: 360px;
  min-width: 360px;
  position: relative;
  border: 1px solid #979797;
  border-radius: 5px;
  min-height: 480px;
  max-height: 480px;
  cursor: pointer; }
  @media (max-width: 768.98px) {
    .scholarship-card {
      max-width: unset;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); } }
  .scholarship-card:hover {
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1); }
  .scholarship-card__wrapper-link {
    display: inherit;
    position: relative; }
    .scholarship-card__wrapper-link .scholarship-card__saved-back {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translate(-20px, -20px);
      background: #1E5558; }
  .scholarship-card__top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
    min-height: 100px;
    cursor: pointer; }
    .scholarship-card__top .__top-leading {
      display: flex;
      align-items: center;
      justify-content: center; }
  .scholarship-card__logo {
    max-height: 100px;
    max-width: 100%;
    min-height: 100px;
    object-position: top;
    object-fit: contain; }
  .scholarship-card__award-container {
    margin-top: 10px;
    min-height: 50px;
    background-color: #eceaea;
    border-radius: 5px;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    border-radius: 5px; }
    .scholarship-card__award-container__award-text {
      color: #1a282c;
      position: relative;
      top: 16px;
      left: 7px; }
    .scholarship-card__award-container__award-amount {
      color: #1a282c;
      position: relative;
      top: 16px;
      left: 10px;
      font-size: 20px; }
    .scholarship-card__award-container img {
      width: 40px;
      height: 40px;
      float: right;
      position: relative;
      top: 5px; }
  .scholarship-card__title {
    margin-bottom: 20px;
    min-height: 60px; }
  .scholarship-card__donations-progress-bar {
    margin-bottom: 15px;
    cursor: pointer; }
    .scholarship-card__donations-progress-bar__last-donations {
      color: #1a282c;
      margin-bottom: 10px; }
      .scholarship-card__donations-progress-bar__last-donations .scholarship-body__card-meta-label {
        display: unset; }
    .scholarship-card__donations-progress-bar__progress-container {
      margin-bottom: 15px; }
      .scholarship-card__donations-progress-bar__progress-container .progress-complete-cell-type {
        margin-bottom: 15px; }
        .scholarship-card__donations-progress-bar__progress-container .progress-complete-cell-type .BodySmallRegularBlack {
          font-weight: bold; }
        .scholarship-card__donations-progress-bar__progress-container .progress-complete-cell-type .progress-complete-cell-type__bar-container {
          max-width: 100%; }
    .scholarship-card__donations-progress-bar__donations-amount-status {
      margin-bottom: 15px; }
    .scholarship-card__donations-progress-bar__donations-amount-status .scholarship-body__card-meta-label {
      display: unset; }
    .scholarship-card__donations-progress-bar__donations-amount-status span {
      font-weight: bold; }
  .scholarship-card__excerpt {
    margin-bottom: 11px;
    min-height: 50px;
    max-height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
    .scholarship-card__excerpt p {
      color: #696969;
      min-height: 30px;
      max-height: 30px;
      margin-top: 3px;
      line-height: 1.8; }
  .scholarship-card__programs-status {
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-right: 5px;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 40px;
    align-items: center; }
    .scholarship-card__programs-status__scholarship-text {
      font-size: 14px;
      color: #592840;
      background-color: #eceaea;
      padding: 6px;
      border-radius: 5px; }
    .scholarship-card__programs-status__scholarship-status {
      font-size: 14px;
      color: #592840;
      background-color: #eceaea;
      margin-left: 15px;
      padding: 6px;
      border-radius: 5px; }
  .scholarship-card__footer-button {
    min-height: 30px;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    align-items: center;
    margin-top: auto;
    padding-top: 13px;
    border-top: solid 1px #f0f2f2; }
    .scholarship-card__footer-button__learn-more {
      cursor: pointer;
      color: blue;
      float: right; }
      .scholarship-card__footer-button__learn-more .account-switcher__arrow {
        margin: 0px 8px -2px 3px; }
      .scholarship-card__footer-button__learn-more path {
        fill: #1C21DF; }
  .scholarship-card__tag {
    padding: 5px 12px;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px; }
  .scholarship-card__tags {
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    .scholarship-card__tags__limited-toggle-btn {
      display: flex;
      flex: 0 0 10px; }
      .scholarship-card__tags__limited-toggle-btn .arrow-right {
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-left: 7px solid #1C21DF;
        cursor: pointer; }
    .scholarship-card__tags__limited-tags {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 10px; }
  .scholarship-card__tags-popup {
    position: absolute;
    background: #ffffff;
    z-index: 2;
    max-width: 312px;
    padding: 0px 24px 0px;
    overflow-y: hidden;
    border: 1px solid #D9D9C9;
    height: 100%; }
    .scholarship-card__tags-popup .scholarship-card__tags {
      justify-content: unset; }
    .scholarship-card__tags-popup.tags-popup__hide {
      display: none; }
    .scholarship-card__tags-popup.tags-popup__show {
      display: block; }
    .scholarship-card__tags-popup__closed-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      margin-right: 10px;
      cursor: pointer; }
  .scholarship-card__date {
    display: flex;
    align-items: center;
    margin-top: auto;
    border-top: solid 1px #f0f2f2;
    padding-top: 13px;
    justify-content: space-between;
    cursor: pointer; }
    .scholarship-card__date .scholarship-card__date-calendar {
      display: flex;
      align-items: center;
      flex: 3; }
      .scholarship-card__date .scholarship-card__date-calendar svg {
        min-width: 15px; }
    .scholarship-card__date .scholarship-card__eligible {
      display: flex;
      align-items: center; }
  .scholarship-card__date-label {
    color: #1a282c;
    font-family: 'Inter-Medium';
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    margin-left: 5px;
    margin-right: 5px; }
    @media (max-width: 1023.98px) {
      .scholarship-card__date-label {
        line-height: 1.2;
        margin-right: 10px; } }
  .scholarship-card__date-section {
    display: flex;
    align-items: center;
    padding-top: 13px;
    justify-content: space-between;
    cursor: pointer;
    margin-top: auto; }
    .scholarship-card__date-section .scholarship-card__date-calendar-section {
      display: flex;
      align-items: center;
      flex: 3; }
      .scholarship-card__date-section .scholarship-card__date-calendar-section svg {
        min-width: 15px; }
  .scholarship-card__date-label-section {
    font-family: 'Inter-Medium';
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    color: #1a282c;
    margin-left: 5px;
    margin-right: 5px;
    color: #1a282c; }
    @media (max-width: 1023.98px) {
      .scholarship-card__date-label-section {
        line-height: 1.2;
        margin-right: 10px; } }
  .scholarship-card .eligible-check {
    color: #1E5558; }

.scholarship__landing__page {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  height: 30px; }

.scholarship__tile__content {
  min-height: 40px; }

.scholarship-card__donations-progress-bar__donations-amount-status {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px; }

.goal__scholarship {
  font-size: 18px !important; }

.donation-goal-scholarship-tile {
  min-height: 50px;
  justify-content: space-between;
  background-color: #eceaea;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  border-radius: 5px; }
  .donation-goal-scholarship-tile span {
    font-size: 20px;
    position: relative;
    top: 10px;
    left: 10px; }
  .donation-goal-scholarship-tile img {
    width: 40px;
    height: 40px;
    float: right; }

.scholarship-card-sponsor-form-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 60px; }

.scholarship-card-sponsor-form-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.scholarship-card-sponsor-form-description {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap; }
  .scholarship-card-sponsor-form-description__excerpt {
    flex: 1 0 50%;
    max-height: 165px;
    min-height: 165px;
    overflow: hidden; }
    .scholarship-card-sponsor-form-description__excerpt ul {
      list-style-type: disc;
      padding-left: 20px;
      max-height: 165px;
      min-height: 165px;
      overflow: hidden; }
    .scholarship-card-sponsor-form-description__excerpt p {
      padding-right: 10px;
      line-height: 2; }
  .scholarship-card-sponsor-form-description__sponser-image {
    flex: 1 0 44%;
    text-align: center; }
    .scholarship-card-sponsor-form-description__sponser-image__scholarship-card-image {
      width: 100%; }

.scholarship-card-sponsor-form-tags {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-top: 10px;
  align-items: center; }
  .scholarship-card-sponsor-form-tags__single-tag {
    background: #ffa3d1;
    padding: 5px 10px;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 14px;
    height: fit-content; }

.scholarship-card-sponsor-form-learn-more-container {
  border-top: 2px solid #e4e2df;
  padding-top: 8px;
  margin-top: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end; }
  .scholarship-card-sponsor-form-learn-more-container #task-page__cta {
    padding: 5px 10px 5px 10px;
    line-height: normal; }
