.color__black {
  color: #1a282c; }

.color__white {
  color: #ffffff; }

.color__beige {
  color: #D9D9C9; }

.color__pink {
  color: #ffa3d1; }

.color__brown {
  color: #db7b1c; }

.color__light-blue {
  color: #98dde7; }

.color__blue {
  color: #1C21DF; }

.color__light-grey {
  color: #f6f6f6; }

.color__red {
  color: #FF542D; }

.color__green {
  color: #1E5558; }

.color__grey {
  color: #979797; }

.color__yellow {
  color: #ffcd00; }

.color__dark-grey {
  color: #737373; }

.color__soft-black {
  color: #364e55; }

.color__faint-grey {
  color: #f0f2f2; }

.color__wild-sand-grey {
  color: #e4e2df; }

.background-color__black {
  background-color: #1a282c; }

.background-color__white {
  background-color: #ffffff; }

.background-color__beige {
  background-color: #D9D9C9; }

.background-color__pink {
  background-color: #ffa3d1; }

.background-color__brown {
  background-color: #db7b1c; }

.background-color__light-blue {
  background-color: #98dde7; }

.background-color__blue {
  background-color: #1C21DF; }

.background-color__light-grey {
  background-color: #f6f6f6; }

.background-color__red {
  background-color: #FF542D; }

.background-color__green {
  background-color: #1E5558; }

.background-color__grey {
  background-color: #979797; }

.background-color__yellow {
  background-color: #ffcd00; }

.background-color__dark-grey {
  background-color: #737373; }

.background-color__soft-black {
  background-color: #364e55; }

.background-color__faint-grey {
  background-color: #f0f2f2; }

.background-color__wild-sand-grey {
  background-color: #e4e2df; }

.application-review-attachment {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #f6f6f6; }

.application-review-attachment .paper-icon {
  margin: 5px 0px 0px 30px; }

.application-review-attachment__icon * {
  filter: none; }

.application-review-attachment__main {
  display: flex;
  align-items: self-end; }
  .application-review-attachment__main .view-recommender-record {
    cursor: pointer;
    margin-left: 15px; }
  .application-review-attachment__main .edit-name-and-email-id {
    margin-left: 15px; }
  .application-review-attachment__main .delete-recom {
    margin-left: 15px; }

.application-review-attachment__heading {
  margin: 0;
  line-height: 1.5;
  color: #1a282c;
  font-family: 'Inter-Medium';
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal; }
  .application-review-attachment__heading .application-review-attachment__preview-image {
    max-width: 500px; }
  .application-review-attachment__heading .application-review-attachment__preview-video {
    max-width: 500px;
    min-width: 300px; }
  .application-review-attachment__heading .application-review-attachment__preview-document {
    max-width: 500px;
    display: flex;
    align-items: center; }
  .application-review-attachment__heading .application-review-attachment__preview .audio-file-player {
    margin-bottom: 35px; }

.application-review-attachment__small {
  display: block;
  line-height: 1.5;
  color: #737373;
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal; }

.application-review-attachment__trigger {
  padding: 5px 12px;
  cursor: pointer;
  color: #1C21DF;
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  border-radius: 5px;
  line-height: 1.5; }
  .application-review-attachment__trigger.view-resource-file {
    font-family: 'Inter-Regular';
    margin-right: 5px;
    border: 1px solid #1C21DF;
    border-radius: 15px;
    color: #1C21DF; }
  .application-review-attachment__trigger.view-resource {
    background: #f6f6f6;
    margin-right: 5px;
    color: black; }
  .application-review-attachment__trigger.edit-recommender {
    background: #f6f6f6;
    color: black; }

.application-review-attachment__trigger-view-file {
  margin: 0 0 0 auto;
  padding: 9px 15px;
  cursor: pointer;
  font-family: 'Inter-Regular';
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  border-radius: 5px;
  line-height: 1.5; }
  .application-review-attachment__trigger-view-file.view-resource-file {
    font-family: 'Inter-Regular';
    margin-right: 5px;
    border: 1px solid #1C21DF;
    border-radius: 15px;
    color: #1C21DF; }
  .application-review-attachment__trigger-view-file.view-resource {
    background: #f6f6f6;
    margin-right: 5px;
    color: black; }
  .application-review-attachment__trigger-view-file.edit-recommender {
    background: #f6f6f6;
    color: black; }

.recommendation-file {
  margin-right: 10px; }

.reviewer-page__application-details .modal__inner {
  align-items: flex-start; }
  .reviewer-page__application-details .modal__inner #review-details-page__recommendation-modal-content {
    width: 100%; }
    .reviewer-page__application-details .modal__inner #review-details-page__recommendation-modal-content .review-details-page-popup__user-details {
      display: flex;
      padding-bottom: 15px;
      border-bottom: 2px dashed #e6e9d7; }
    .reviewer-page__application-details .modal__inner #review-details-page__recommendation-modal-content .review-details-page-popup__footer {
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 20px; }
      .reviewer-page__application-details .modal__inner #review-details-page__recommendation-modal-content .review-details-page-popup__footer #review-details_close-btn {
        padding: 8px 20px; }

.download-video-message {
  color: unset !important; }

.application-review-attachment-small-pdf-help-desc {
  margin: 5px 0px 0px 30px;
  font-size: 12px;
  font-family: 'Inter-Regular', sans-serif; }
