#review-details-page__recommendation-modal-content .application-review-text--first {
  margin: 25px 0 0; }

#review-details-page__recommendation-modal-content .application-review-text {
  margin: 25px 0 0; }
  #review-details-page__recommendation-modal-content .application-review-text__heading {
    display: flex; }
  #review-details-page__recommendation-modal-content .application-review-text .application-review-text__content {
    margin: 5px 0px 0px 30px; }
  #review-details-page__recommendation-modal-content .application-review-text .application-review-text__word-count {
    margin-top: 10px;
    margin-left: 30px; }
